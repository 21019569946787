import React, { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router'

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const router = useRouter();

    const getInitialCart = () => {
        if (typeof window !== 'undefined') {
            const storedCart = localStorage.getItem('cart');
            return storedCart ? JSON.parse(storedCart) : [];
        }
        return [];
    };

    const [cart, setCart] = useState(getInitialCart());
    const [bands, setBands] = useState([]);
    const [popUp, setPopUp] = useState(false);

    useEffect(() => {
        localStorage.clear();
        const storedBands = localStorage.getItem('bands');
        if (storedBands) {
            setBands(JSON.parse(storedBands));
        } else {
            const getData = async () => {
                const query = await fetch('https://tickets.adventureresortkufri.com/api/v1/getplans?token=adventure');
                const res = await query.json();
                setBands(res.tickets);
                localStorage.setItem('bands', JSON.stringify(res.tickets));
            };
            getData();
        }
    }, []);

    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            setCart(JSON.parse(storedCart));
        }
    }, []);

    const removeFromCart = (itemId) => {
        setCart((prevItems) =>
            prevItems.filter((item) => item.id !== itemId)
        );
    };

    const handleAddToCart = (band, quantity) => {
        if (window && window.fbq) {
            window.fbq('track', 'AddToCart');
        }
        const bandToAdd = { ...band, quantity: quantity };
        const isItemInCart = cart.some((cartItem) => cartItem.id === band.id);

        if (isItemInCart) {
            const updatedCart = cart.map((cartItem) => {
                if (cartItem.id === band.id) {
                    return { ...cartItem, quantity: quantity };
                }
                return cartItem;
            });
            setCart(updatedCart);
        } else {
            setCart((prevCart) => [...prevCart, bandToAdd]);
        }
        setPopUp(true);
    };

    const handleBookNow = (band, quantity) => {
        handleAddToCart(band, quantity); // Add item to cart
        router.push('/tickets/cart/checkout', undefined, { shallow: true }); // Navigate to cart page
    };
    const handleIncrementQuantity = (bandId) => {
        setBands((prevBands) => {
            const updatedBands = prevBands.map((band) => {
                if (band.id === bandId) {
                    const newQuantity = parseInt(band.quantity) + 1;
                    updateCartItemQuantity(bandId, newQuantity);
                    return { ...band, quantity: newQuantity };
                }
                return band;
            });

            localStorage.setItem('bands', JSON.stringify(updatedBands));
            setBands(updatedBands);
            return updatedBands;
        });
    };

    const handleDecrementQuantity = (bandId) => {
        setBands((prevBands) => {
            const updatedBands = prevBands.map((band) => {
                if (band.id === bandId && band.quantity > 1) {
                    const newQuantity = band.quantity - 1;
                    updateCartItemQuantity(bandId, newQuantity);
                    return { ...band, quantity: newQuantity };
                }
                return band;
            });

            // Update bands in local storage and cart context
            localStorage.setItem('bands', JSON.stringify(updatedBands));
            setBands(updatedBands);

            return updatedBands;
        });
    };

    const updateCartItemQuantity = (bandId, newQuantity) => {
        setCart((prevCart) => {
            return prevCart.map((item) => {
                if (item.id === bandId) {
                    return { ...item, quantity: newQuantity };
                }
                return item;
            });
        });
    };

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const cartItem = cart.length

    return (
        <CartContext.Provider value={{
            cart,
            cartItem,
            bands,
            popUp,
            setPopUp,
            setBands,
            setCart,
            handleAddToCart,
            removeFromCart,
            handleBookNow,
            updateCartItemQuantity,
            handleIncrementQuantity,
            handleDecrementQuantity
        }}>
            {children}
        </CartContext.Provider>
    );
};


// {
//     id: '101',
//     title: 'Yellow Band',
//     quantity: 1,
//     band_color: '#F9F5E2',
//     band_bg_color: '#FFCE1F',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },
// {
//     id: '102',
//     title: 'Blue Band',
//     quantity: 1,
//     band_color: '#EBF6FF',
//     band_bg_color: '#42A5F5',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },
// {
//     id: '103',
//     title: 'Purple Band',
//     quantity: 1,
//     band_color: '#F6EAFF',
//     band_bg_color: '#A564D3',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },
// {
//     id: '104',
//     title: 'Red Band',
//     quantity: 1,
//     band_color: '#FFEAE9',
//     band_bg_color: '#F25C54',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },
// {
//     id: '105',
//     title: 'Orange Band',
//     quantity: 1,
//     band_color: '#FFF2EC',
//     band_bg_color: '#FF8552',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },
// {
//     id: '106',
//     title: 'Pink Band',
//     quantity: 1,
//     band_color: '#FFF2EC',
//     band_bg_color: '#FF5271',
//     regular_price: '₹800.00',
//     offered_price: '₹720.00',
//     description: 'Indoor Activities: Dashing Car, Haunted House, 5D Theatre, Mirror Maze, Infinity Room and Tunnel Ride (all one time)'
// },